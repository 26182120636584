import type {FC} from 'react';
import React from "react";
import {FormikProps} from 'formik';
import {Grid} from '@mui/material';
import {ApplicationEditAdmin} from "./application-edit-admin";
import {ApplicationEditBasic} from "./application-edit-basic";
import {ApplicationEditContact} from "./application-edit-contact";
import {ApplicationEditEducation} from "./application-edit-education";
import {ApplicationEditProfessional} from "./application-edit-professional";
import {ApplicationEditApplication} from "./application-edit-application";
import {Application} from "../../api/applications";

interface ApplicationCreateFormProps {
  formik: FormikProps<Application>
}

export const ApplicationCreateForm: FC<ApplicationCreateFormProps> = (props) => {
  const { formik } = props;

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <ApplicationEditAdmin formik={formik}/>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ApplicationEditBasic formik={formik}/>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ApplicationEditContact formik={formik}/>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ApplicationEditEducation formik={formik}/>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ApplicationEditProfessional formik={formik}/>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ApplicationEditApplication formik={formik}/>
      </Grid>
    </Grid>
  );
};
