import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Button, Card, Radio, Stack, Typography} from '@mui/material';
import {DataStepValues, OperationDataType} from "../../types/operations";

interface OperationTypeOption {
  description: string;
  title: string;
  value: OperationDataType;
}

export const dataTypeOptions: OperationTypeOption[] = [
  {
    description: 'Ex: Martin DUPONT',
    title: 'Candidature',
    value: 'applications'
  },
  {
    description: 'Ex: SIE25',
    title: 'Promotion',
    value: 'sessions'
  },
  {
    description: 'Ex: SIE',
    title: 'Formation',
    value: 'majors'
  }
];

interface DataTypeStepProps {
  onNext?: () => void;
  onBack?: () => void;
  onChange: (value: OperationDataType) => void;
  initialValue?: OperationDataType;
  values: {[name: string]: DataStepValues}
}

export const DataTypeStep: FC<DataTypeStepProps> = (props) => {
  const { onBack, onNext, onChange, initialValue: initialValueProp = "session", values, ...other } = props;
  const initialValue: OperationDataType = useMemo(
    () => dataTypeOptions.find((elt) => elt.value === initialValueProp)?.value ?? dataTypeOptions[0].value,
    [initialValueProp]
  )
  const [dataType, setDataType] = useState<OperationDataType>(initialValue);

  const handleDataTypeChange = useCallback(
    (dataType: OperationDataType): void => {
      setDataType(dataType);
      onChange(dataType)
    },
    [onChange]
  );

  useEffect(() => {
      onChange(initialValue)
    },
    [onChange, initialValue]
  )

  return (
    <Stack
      spacing={3}
      {...other}
    >
      <div>
        <Typography variant="h6">
          Un objet de type ...
        </Typography>
      </div>
      <Stack spacing={2}>
        {dataTypeOptions.map((option) => (
          <Card
            key={option.value}
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              p: 2,
              ...(dataType === option.value && {
                backgroundColor: 'primary.alpha12',
                boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`
              })
            }}
            onClick={(): void => handleDataTypeChange(option.value)}
            variant="outlined"
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <Radio
                checked={dataType === option.value}
                color="primary"
              />
              <div>
                <Typography variant="subtitle1">
                  {option.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  {option.description}
                </Typography>
              </div>
            </Stack>
          </Card>
        ))}
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Button
          endIcon={(<ArrowForwardIcon />)}
          onClick={onNext}
          variant="contained"
        >
          Continuer
        </Button>
        <Button
          color="inherit"
          onClick={onBack}
        >
          Retour
        </Button>
      </Stack>
    </Stack>
  );
};

DataTypeStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onChange: PropTypes.func.isRequired
};
