import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Button, Card, Radio, Stack, Typography} from '@mui/material';
import {DataStepValues, OperationType} from "../../types/operations";

interface OperationTypeOption {
  description: string;
  title: string;
  value: OperationType;
}

export const optTypeOptions: OperationTypeOption[] = [
  {
    description: 'Ajouter une candidature',
    title: 'Créer',
    value: 'create'
  },
  {
    description: 'Modifier une promotion',
    title: 'Modifier',
    value: 'edit'
  },
  {
    description: 'Supprimer une candidature',
    title: 'Supprimer',
    value: 'remove'
  }
];

interface OperationTypeStepProps {
  onNext?: () => void;
  onBack?: () => void;
  onChange: (value: OperationType) => void;
  initialValue?: OperationType;
  values: {[name: string]: DataStepValues}
}

export const OperationTypeStep: FC<OperationTypeStepProps> = (props) => {
  const { onBack, onNext, onChange, initialValue: initialValueProp = "create", values, ...other } = props;
  const initialValue = useMemo(
    () => optTypeOptions.find((elt) => elt.value === initialValueProp)?.value ?? optTypeOptions[0].value,
    [initialValueProp]
  )
  const [optType, setOptType] = useState<OperationType>(initialValue);

  const handleOptTypeChange = useCallback(
    (optType: OperationType): void => {
      setOptType(optType);
      onChange(optType);
    },
    [onChange]
  );

  useEffect(() => {
      onChange(initialValue)
    },
    [onChange, initialValue]
  )

  return (
    <Stack
      spacing={3}
      {...other}
    >
      <div>
        <Typography variant="h6">
          Je veux...
        </Typography>
      </div>
      <Stack spacing={2}>
        {optTypeOptions.map((option) => (
          <Card
            key={option.value}
            sx={{
              alignItems: 'center',
              cursor: 'pointer',
              display: 'flex',
              p: 2,
              ...(optType === option.value && {
                backgroundColor: 'primary.alpha12',
                boxShadow: (theme) => `${theme.palette.primary.main} 0 0 0 1px`
              })
            }}
            onClick={(): void => handleOptTypeChange(option.value)}
            variant="outlined"
          >
            <Stack
              direction="row"
              spacing={2}
            >
              <Radio
                checked={optType === option.value}
                color="primary"
              />
              <div>
                <Typography variant="subtitle1">
                  {option.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  {option.description}
                </Typography>
              </div>
            </Stack>
          </Card>
        ))}
      </Stack>
      <div>
        <Button
          endIcon={(<ArrowForwardIcon />)}
          onClick={onNext}
          variant="contained"
        >
          Continuer
        </Button>
      </div>
    </Stack>
  );
};

OperationTypeStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onChange: PropTypes.func.isRequired
};
