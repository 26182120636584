import {FormikProps} from "formik";
import React, {FC} from "react";
import {Card, CardContent, CardHeader, Divider, Grid, TextField,} from "@mui/material";
import {Major} from "../../api/majors";
import {majorPlaceholder} from "../../utils/defaults";

interface MajorCreateFormProps {
  major?: Major,
  formik: FormikProps<Major>
}



export const MajorCreateForm: FC<MajorCreateFormProps> = (props) => {

  const {major = majorPlaceholder, formik, ...other} = props

  return (
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Card {...other}>
            <CardHeader title="Détails" />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label="Acronyme"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(formik.touched.fullname && formik.errors.fullname)}
                    fullWidth
                    helperText={formik.touched.fullname && formik.errors.fullname}
                    label="Nom complet"
                    name="fullname"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.fullname}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  )
}