import {FormikProps} from "formik";
import React, {FC} from "react";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import {Session} from "../../api/sessions";
import {useMajorsListQuery} from "../../api/majors";

interface SessionCreateFormProps {
  formik: FormikProps<Session>
}



export const SessionCreateForm: FC<SessionCreateFormProps> = (props) => {

  const {formik, ...other} = props

  const {data: majorsRaw = []} = useMajorsListQuery()

  const majors = [...majorsRaw].sort((a, b) => a.name.localeCompare(b.name))
  return (
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Card {...other}>
            <CardHeader title="Détails" />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(formik.touched.year && formik.errors.year)}
                    fullWidth
                    helperText={formik.touched.year && formik.errors.year}
                    label="Année"
                    name="year"
                    inputProps={{
                      step: 1,
                      min: 1990,
                      max: 3000,
                      type: 'number'
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.year}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Autocomplete
                    options={majors}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => formik.setFieldValue('major', value?.id)}
                    value={majors.find(elt => elt.id === formik.values.major) ?? null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(formik.touched.major && formik.errors.major)}
                        fullWidth
                        helperText={formik.touched.major && formik.errors.major}
                        label="Formation"
                        name="major"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    error={Boolean(formik.touched.number && formik.errors.number)}
                    fullWidth
                    helperText={formik.touched.number && formik.errors.number}
                    label="Numéro"
                    name="number"
                    inputProps={{
                      step: 1,
                      min: 1,
                      type: 'number'
                    }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.number}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    label="Candidatures ouvertes"
                    onChange={(event, checked) => {
                      formik.setFieldValue("accept_applications", checked)
                    }}
                    value={formik.values.accept_applications}
                    control={
                    <Checkbox
                      name="accept_applications"
                    />}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  )
}