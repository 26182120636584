import type {FC} from 'react';
import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Button, Stack, Typography} from '@mui/material';
import {
  DataStepValues,
  OperationDataType,
  OperationType,
  SelectionPatchedType,
  SelectionType
} from "../../types/operations";
import {ApplicationCreateForm} from "../application/application-create-form";
import {SessionCreateForm} from "../sessions/session-create-form";
import {FormikHelpers, useFormik} from 'formik';
import {applicationPlaceholder, majorPlaceholder, sessionPlaceholder} from "../../utils/defaults";
import {
  Session,
  useSessionsCreateMutation,
  useSessionsDestroyMutation,
  useSessionsPartialUpdateMutation
} from "../../api/sessions";
import {
  Application,
  useApplicationsCreateMutation,
  useApplicationsDestroyMutation,
  useApplicationsPartialUpdateMutation
} from "../../api/applications";
import {
  Major,
  useMajorsCreateMutation,
  useMajorsDestroyMutation,
  useMajorsPartialUpdateMutation
} from "../../api/majors";
import {applicationCreateSchema, applicationUpdateSchema, majorSchema, sessionSchema} from "../../utils/yup-schemas";
import toast from "react-hot-toast";
import {MajorCreateForm} from "../majors/major-create-form";
import {DateTime} from "luxon";

interface OperationApplyStepProps {
  onNext?: () => void;
  onBack?: () => void;
  onChange: (value: SelectionType) => void;
  initialValue?: SelectionType;
  values: {[name: string]: DataStepValues}
}

export const OperationApplyStep: FC<OperationApplyStepProps> = (props) => {
  const { onBack, onNext, onChange, values, initialValue, ...other } = props;

  const [createApplication] = useApplicationsCreateMutation()
  const [createSession] = useSessionsCreateMutation()
  const [createMajor] = useMajorsCreateMutation()

  const [updateApplication] = useApplicationsPartialUpdateMutation()
  const [updateSession] = useSessionsPartialUpdateMutation()
  const [updateMajor] = useMajorsPartialUpdateMutation()

  const [removeApplication] = useApplicationsDestroyMutation()
  const [removeSession] = useSessionsDestroyMutation()
  const [removeMajor] = useMajorsDestroyMutation()

  const {operationType, dataType} = useMemo(() => {
    return {
      operationType: values["operationType"] as OperationType,
      dataType: values["dataType"] as OperationDataType
    }
  }, [values])


  const actionText = useMemo(() => {
    switch (operationType) {
      case "create":
        return "Créer"
      case "edit":
        return "Modifier"
      case "remove":
        return "Supprimer"
    }
  }, [operationType])


  const {initialValues, validationSchema, nextButtonText, onSubmit, onRemove} = useMemo(() => {

    const removeUnchangedValues = (values: SelectionPatchedType) => {
      if (!values) {
        return {finalValues: undefined, modified: false}
      }
      let finalValues: typeof values = {...values}
      let modified = false
      let key: keyof typeof values;
      for (key in values) {
        if (values[key] !== initialValues[key]) {
          modified = true
        }
        else {
          finalValues[key] = undefined
        }
      }
      return {finalValues, modified}
    }

    const onSubmitSuccess = (helpers: FormikHelpers<any>) => {
      helpers.setStatus({success: true});
      helpers.setSubmitting(false);
      toast.success('Opération réussie');
      if (onNext) {
        onNext()
      }
    }

    const onSubmitError = (error: any, helpers: FormikHelpers<any>) => {
      toast.error('Le formulaire contient des erreurs.');
      helpers.setStatus({ success: false });
      helpers.setErrors(error.data);
      helpers.setSubmitting(false);
    }

    const onSubmitCreateApplication = async (values: Application, helpers: FormikHelpers<Application>) => {
      let finalValues: Application = {
        ...values,
        received_date: DateTime.utc().toISO(),
        interview_date: values.interview_date !== "" ? values.interview_date : undefined
      }
      createApplication({application: finalValues}).unwrap().then(() => {
        onSubmitSuccess(helpers)
      }).catch(error => {
        onSubmitError(error, helpers)
      })
    }

    const onSubmitCreateSession = async (values: Session, helpers: FormikHelpers<Session>) => {
      createSession({session: values}).unwrap().then(() => {
        onSubmitSuccess(helpers)
      }).catch(error => {
        onSubmitError(error, helpers)
      })
    }

    const onSubmitCreateMajor = async (values: Major, helpers: FormikHelpers<Major>) => {
      createMajor({major: values}).unwrap().then(() => {
        onSubmitSuccess(helpers)
      }).catch(error => {
        onSubmitError(error, helpers)
      })
    }

    const onSubmitUpdateApplication = async (values: Application, helpers: FormikHelpers<Application>) => {
      const {finalValues, modified} = removeUnchangedValues(values)
      if (!modified) {
        onSubmitSuccess(helpers)
        return
      }
      updateApplication({id: values.id,  patchedApplication: finalValues as Application}).unwrap().then(() => {
        onSubmitSuccess(helpers)
      }).catch(error => {
        onSubmitError(error, helpers)
      })
    }

    const onSubmitUpdateSession = async (values: Session, helpers: FormikHelpers<Session>) => {
      const {finalValues, modified} = removeUnchangedValues(values)
      if (!modified) {
        onSubmitSuccess(helpers)
        return
      }
      updateSession({id: values.id, patchedSession: finalValues as Session}).unwrap().then(() => {
        onSubmitSuccess(helpers)
      }).catch(error => {
        onSubmitError(error, helpers)
      })
    }

    const onSubmitUpdateMajor = async (values: Major, helpers: FormikHelpers<Major>) => {
      const {finalValues, modified} = removeUnchangedValues(values)
      if (!modified) {
        onSubmitSuccess(helpers)
        return
      }
      updateMajor({id: values.id, patchedMajor: finalValues as Major}).unwrap().then(() => {
        onSubmitSuccess(helpers)
      }).catch(error => {
        onSubmitError(error, helpers)
      })
    }

    switch (dataType) {
      case "applications":
        return {
          initialValues: initialValue as Application ?? {...applicationPlaceholder, survey_date: DateTime.utc().toISO()},
          validationSchema: operationType === "create" ? applicationCreateSchema : applicationUpdateSchema,
          nextButtonText: `${actionText} la candidature`,
          onSubmit: operationType === "create" ? onSubmitCreateApplication : onSubmitUpdateApplication,
          onRemove: removeApplication
        }
      case "sessions":
        return {
          initialValues: initialValue as Session ?? sessionPlaceholder,
          validationSchema: sessionSchema,
          nextButtonText:  `${actionText} la promotion`,
          onSubmit: operationType === "create" ? onSubmitCreateSession : onSubmitUpdateSession,
          onRemove: removeSession
        }
      case "majors":
        return {
          initialValues: initialValue as Major ?? majorPlaceholder,
          validationSchema: majorSchema,
          nextButtonText: `${actionText} la formation`,
          onSubmit: operationType === "create" ? onSubmitCreateMajor : onSubmitUpdateMajor,
          onRemove: removeMajor
        }
    }
  }, [actionText, createMajor, createSession, createApplication, dataType, initialValue, onNext, operationType, removeMajor, removeSession, removeApplication, updateMajor, updateSession, updateApplication])

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      await onSubmit(values, helpers)
    },
  });

  const handleNextWithSubmit = useCallback(async () => {
      if (operationType === "remove" && initialValue?.id) {
        onRemove({id: initialValue.id})
        if (onNext) {
          onNext()
        }
      }
      else {
        await formik.submitForm()
      }
    },
    [formik, initialValue, onNext, onRemove, operationType]
  )

  const dataLabel = useMemo(() => {
      switch (dataType) {
        case "applications":
          return " : " + (initialValue as Application)?.candidate.display_name
        case "sessions":
          return " : " + (initialValue as Session)?.name
        case "majors":
          return " : " + (initialValue as Major)?.name
        case undefined:
          return ""
      }
    }, [initialValue, dataType]
  )

  return (
    <Stack
      spacing={3}
      {...other}
    >
      {operationType !== "remove" ?
        (<Stack spacing={2}>
        {dataType === "applications" && <ApplicationCreateForm formik={formik}/>}
        {dataType === "sessions" && <SessionCreateForm formik={formik}/>}
        {dataType === "majors" && <MajorCreateForm formik={formik}/>}
      </Stack>) : (
        <Stack spacing={2}>
          <Typography variant="h6">
            {`L'objet suivant sera supprimé ${dataLabel}`}
          </Typography>
        </Stack>
        )}
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Button
          endIcon={(<ArrowForwardIcon/>)}
          onClick={handleNextWithSubmit}
          variant="contained"
        >
          {nextButtonText}
        </Button>
        <Button
          color="inherit"
          onClick={onBack}
        >
          Retour
        </Button>
      </Stack>
    </Stack>
  );
};

OperationApplyStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onChange: PropTypes.func.isRequired
};
