import type {FC} from 'react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Autocomplete, Button, Grid, Stack, TextField, Typography} from '@mui/material';
import {DataStepValues, SelectionType} from "../../types/operations";
import {useFormik} from "formik";
import * as yup from "yup";
import {useSessionsListQuery} from "../../api/sessions";
import {useApplicationsListQuery} from "../../api/applications";
import {useMajorsListQuery} from "../../api/majors";

interface DataSelectionStepProps {
  onNext?: () => void;
  onBack?: () => void;
  onChange: (value: SelectionType | undefined) => void;
  initialValue?: SelectionType;
  values: {[name: string]: DataStepValues}
}

export const DataSelectionStep: FC<DataSelectionStepProps> = (props) => {
  const { onBack, onNext, onChange, initialValue: initialValueProp, values, ...other } = props;
  const initialValue = useMemo(
    () => initialValueProp,
    [initialValueProp]
  )
  const [dataSelection, setDataSelection] = useState<SelectionType | undefined>(initialValue);

  const {data: applicationsRaw = []} = useApplicationsListQuery({})
  const {data: sessionsRaw = []} = useSessionsListQuery()
  const {data: majorsRaw = []} = useMajorsListQuery()

  const handleDataSelectionChange = useCallback(
    (dataSelection?: SelectionType): void => {
      setDataSelection(dataSelection);
    },
    []
  );

  useEffect(() => {
      onChange(initialValue)
    },
    [onChange, initialValue]
  )

  const dataType = values["dataType"]

  const applications = [...applicationsRaw].sort(((a, b) => a.session - b.session))
  const sessions = [...sessionsRaw].sort(((a, b) => a.name.localeCompare(b.name)))
  const majors = [...majorsRaw].sort(((a, b) => a.name.localeCompare(b.name)))

  const formik = useFormik<{id: number}>({
    initialValues: {id: -1},
    validationSchema: yup.object({id: yup.number().required()}),
    onSubmit: async (values, helpers) => {
      if (values.id !== -1) {
        helpers.setStatus({success: true});
        helpers.setSubmitting(false);
        onChange(dataSelection)
        if (onNext) {
          onNext()
        }
      }
      else {
        helpers.setStatus({ success: false });
        helpers.setErrors({id: "Un choix est requis."});
        helpers.setSubmitting(false);
      }
    }
  });

  const handleNextWithSubmit = useCallback(async () => {
      await formik.submitForm()
    },
    [formik]
  )

  return (
    <Stack
      spacing={3}
      {...other}
    >
      <div>
        <Typography variant="h6">
          Je sélectionne ...
        </Typography>
      </div>
      <Stack spacing={2}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            {dataType === "applications" && <Autocomplete
              options={applications}
              getOptionLabel={(option) => {
                const session = sessions.find(elt => elt.id === option.session) ?? {name: ""}
                return `${option.candidate.display_name} (${session.name})`
              }}
              groupBy={(option) => sessions.find(elt => elt.id === option.session)?.name ?? `${option.session}`}
              onChange={(event, value) => {
                formik.setFieldValue('id', value?.id)
                handleDataSelectionChange(value ?? undefined)
              }}
              value={applications.find(elt => elt.id === formik.values.id) ?? null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formik.touched.id && formik.errors.id)}
                  fullWidth
                  helperText={formik.touched.id && formik.errors.id}
                  label="Candidature"
                  name="id"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              )}
            />}
            {dataType === "sessions" && <Autocomplete
                options={sessions}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  formik.setFieldValue('id', value?.id)
                  handleDataSelectionChange(value ?? undefined)
                }}
                groupBy={(option) => majors.find(elt => elt.id === option.major)?.name ?? `${option.major}`}
                value={sessions.find(elt => elt.id === formik.values.id) ?? null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(formik.touched.id && formik.errors.id)}
                    fullWidth
                    helperText={formik.touched.id && formik.errors.id}
                    label="Promotion"
                    name="id"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                )}
            />}
            {dataType === "majors" && <Autocomplete
                options={majors}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  formik.setFieldValue('id', value?.id)
                  handleDataSelectionChange(value ?? undefined)
                }}
                value={majors.find(elt => elt.id === formik.values.id) ?? null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(formik.touched.id && formik.errors.id)}
                    fullWidth
                    helperText={formik.touched.id && formik.errors.id}
                    label="Formation"
                    name="id"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                )}
            />}
          </Grid>
        </Grid>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Button
          endIcon={(<ArrowForwardIcon />)}
          onClick={handleNextWithSubmit}
          variant="contained"
        >
          Continuer
        </Button>
        <Button
          color="inherit"
          onClick={onBack}
        >
          Retour
        </Button>
      </Stack>
    </Stack>
  );
};

DataSelectionStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onChange: PropTypes.func.isRequired
};
