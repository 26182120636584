import type {FC} from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import type {Orientation, StepIconProps} from '@mui/material';
import {Avatar, Grid, Step, StepContent, StepLabel, Stepper, SvgIcon, Typography} from '@mui/material';
import {OperationTypeStep, optTypeOptions} from "./operation-type-step";
import {dataTypeOptions, DataTypeStep} from "./operation-data-type";
import {DataSelectionStep} from "./operation-data-selection";
import {OperationApplyStep} from "./operation-operation-apply";
import {OperationDataType, OperationType, SelectionType} from "../../types/operations";
import {Major} from "../../api/majors";
import {Session} from "../../api/sessions";
import {Application} from "../../api/applications";

type StepState = {
  active: number;
  previous: number;
}

interface OperationFormProps {
  orientation?: Orientation
}

const StepIcon: FC<StepIconProps> = (props) => {
  const { active, completed, icon } = props;

  const highlight = active || completed;

  return (
    <Avatar
      sx={{
        height: 40,
        width: 40,
        ...(highlight && {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText'
        })
      }}
      variant="rounded"
    >
      {
        completed
          ? (
            <SvgIcon>
              <CheckIcon />
            </SvgIcon>
          )
          : icon
      }
    </Avatar>
  );
};

export const OperationForm: FC<OperationFormProps> = (props) => {

  const { orientation = "vertical" } = props
  const initialStep = useMemo(() => ({
    active: 0,
    previous: 0
  }), [])

  const [activeStep, setActiveStep] = useState<StepState>(initialStep);
  const [stepsState, setStepsState] = useState([initialStep])
  const [complete, setComplete] = useState<boolean>(false);

  const [operationType, setOperationType] = useState<OperationType | undefined>(undefined)
  const [dataType, setDataType] = useState<OperationDataType | undefined>(undefined)
  const [dataSelection, setDataSelection] = useState<SelectionType | undefined>(undefined)

  const [stepsValues, setStepsValues] = useState({
    operationType,
    dataType,
    dataSelection
  })

  useEffect(() => {
    setStepsValues({
      operationType,
      dataType,
      dataSelection,})
    if (complete) {
      setOperationType(undefined)
      setDataType(undefined)
      setDataSelection(undefined)
      setActiveStep(initialStep)
      setStepsState([initialStep])
      setComplete(false)
    }
  },
    [operationType, dataType, dataSelection, complete, initialStep]
  )

  const handleNext = useCallback(
    () => {
      let nextStepNumber = activeStep.active + 1
      const nextStep = {
        active: nextStepNumber,
        previous: activeStep.active
      }
      const newState = [
        ...stepsState,
        nextStep
      ]
      setStepsState(newState)
      setActiveStep(nextStep);
    },
    [activeStep, stepsState]
  );

  const handleNextWithComplete = useCallback(
    () => {
      handleNext()
      setComplete(true)
    },
    [handleNext]
  );

  const handleBack = useCallback(
    () => {
      const tempStepsState = [...stepsState]
      tempStepsState.pop() // Get rid of current step
      const previousStep = tempStepsState.pop() // Retreive previous step
      const nextStep = previousStep ?? initialStep
      setActiveStep(nextStep)
      setStepsState([...tempStepsState, nextStep]) // State should be immutable
    },
    [initialStep, stepsState]
  );

  const operationTypeLabel = useMemo(() => {
    return operationType ? ": " + optTypeOptions.find(elt => elt.value === operationType)?.title : ""
  }, [operationType]
  )
  const dataTypeLabel = useMemo(() => {
    return dataType ? ": " + dataTypeOptions.find(elt => elt.value === dataType)?.title : ""
  }, [dataType]
  )
  const dataSelectionLabel = useMemo(() => {
    switch (dataType) {
      case "applications":
        return " : " + (dataSelection as Application)?.candidate.display_name
      case "sessions":
        return " : " + (dataSelection as Session)?.name
      case "majors":
        return " : " + (dataSelection as Major)?.name
      case undefined:
        return ""
    }
  }, [dataSelection, dataType]
  )


  const steps = useMemo(
    () => {
      let additionalSteps
      if (operationType === "create") {
        additionalSteps = [{
          label: "Formulaire de création",
          slug: "dataCreation",
          content: (
            <OperationApplyStep
              onBack={handleBack}
              onNext={handleNextWithComplete}
              onChange={setDataSelection}
              initialValue={dataSelection}
              values={stepsValues}
            />
          )
        }]
      }
      else {
        const label = operationType === "edit" ? `Formulaire de modification` : "Confirmation de suppression"
        additionalSteps = [
          {
            label: `Selection ${dataSelectionLabel}`,
            slug: "dataSelection",
            content: (
              <DataSelectionStep
                onBack={handleBack}
                onNext={handleNext}
                onChange={setDataSelection}
                initialValue={dataSelection}
                values={stepsValues}
              />
            )
          },
          {
            label: label,
            slug: "dataEdition",
            content: (
              <OperationApplyStep
                onBack={handleBack}
                onNext={handleNextWithComplete}
                onChange={setDataSelection}
                initialValue={dataSelection}
                values={stepsValues}
              />
            )
          }
        ]
      }
      return [
        {
          label: `Opération ${operationTypeLabel}`,
          slug: "operationType",
          content: (
            <OperationTypeStep
              onBack={handleBack}
              onNext={handleNext}
              onChange={setOperationType}
              initialValue={operationType}
              values={stepsValues}
            />
          )
        },
        {
          label: `Type de donnée ${dataTypeLabel}`,
          slug: "dataType",
          content: (
            <DataTypeStep
              onBack={handleBack}
              onNext={handleNext}
              onChange={setDataType}
              initialValue={dataType}
              values={stepsValues}
            />
          )
        },
        ...additionalSteps,
      ];
    },
    [operationType, operationTypeLabel, handleBack, handleNext, stepsValues, dataTypeLabel, handleNextWithComplete, dataType, dataSelection, dataSelectionLabel]
  );

  return (
    <>
      <Stepper
        activeStep={activeStep.active}
        orientation={orientation}
        sx={{
          pb: 2,
          '& .MuiStepConnector-line': {
            borderLeftColor: 'divider',
            borderLeftWidth: 2,
            ml: 1,
          }
        }}
      >
        {steps.map((step, index) => {
          const isCurrentStep = activeStep.active === index;

          return (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepIcon}>
                <Typography
                  sx={{ ml: 2 }}
                  variant="overline"
                >
                  {step.label}
                </Typography>
              </StepLabel>
              {orientation === "vertical" && <StepContent
                sx={{
                  borderLeftColor: 'divider',
                  borderLeftWidth: 2,
                  ml: '20px',
                  ...(isCurrentStep && {
                    py: 4
                  })
                }}
              >
                {step.content}
              </StepContent>}
            </Step>
          );
        })}
      </Stepper>
      {orientation === "horizontal" && <Grid container >
        <Grid item xs={12}>
          {!complete && steps[activeStep.active].content}
        </Grid>
      </Grid>}
    </>
  );
};
