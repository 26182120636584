import {Session} from "../api/sessions";
import {DateTime} from "luxon";
import {Major} from "../api/majors";
import {Application} from "../api/applications";

export const applicationPlaceholder: Application = {
  candidate: {
    address: "",
    other_applications: "",
    birth_country: "FR",
    birth_place: "",
    birth_date: "",
    career_objectives: "",
    city: "",
    college: "",
    country: "",
    current_studies: "",
    resume: undefined,
    display_name: "",
    ege_known: undefined,
    email: "",
    firstname: "",
    gender: "H",
    high_school: "",
    id: "",
    ine: "",
    last_jobs: "",
    lastname: "",
    linkedin: "",
    mobile: "",
    motivation: "",
    nationality: "FR",
    nationality2: "",
    native_language: "",
    other_language: "",
    other_language2: "",
    other_language3: "",
    phone: "",
    picture: undefined,
    readings: "",
    responsibilities: "",
    strength: "",
    weaknesses: "",
    zip_code: ""
  },
  id: 0,
  interview_date: "",
  received_date: "",
  validated_date: "",
  enrolled_date: "",
  refused_date: "",
  dropped_date: "",
  last_change_date: "",
  last_change_user: "",
  session: 0,
  state: 0,
  state_name: "",
  notes: "",
}

export const sessionPlaceholder: Session = {
  id: 0,
  major: 0,
  name: "",
  number: 1,
  year: DateTime.now().year,
  accept_applications: false
}

export const majorPlaceholder: Major = {
  id: 0,
  fullname: "",
  name: "",
  slug: ""
}